import  React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ListBrand from '../components/list-brand';
import ListLink from '../components/list-link';
import Image from '../components/Image';

const ContactPage = () => (
    <Layout page="contact-page">
      <Seo title="CONTACT"/>
      <section className="page-head">
        <ListBrand/>
        <ListLink/>
        <h2 className="main-title"><span>SHOWROOM RESERVATION</span></h2>
      </section>
      <section className="page-content full-layout">
        <div className="main-content">
          <div className="content-block">
            <h3 className="sub-title"><span>ショールームご来場ご予約フォーム</span></h3>
          </div>
          <div className="content-block">
            <p className="mb-12">この度はモルソーショールームにご興味をお持ちいただきありがとうございます。<br />
              下記予約フォームに必要事項をご入力のうえご予約をお願い致します。<br />
              なお、予約フォームの送信のみではご予約は確定されておりません。<br />
              弊社担当者からのメール返信、連絡をお待ちください。<br />
              通常1,2日以内に返信させていただきますがお急ぎの場合はお電話でのご確認をお願い致します。<br />
              ショールームに関して何かご不明な点がございましたらお気軽にお問合せ下さい。</p>
            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSdjnjCy1p9PwXdecVYgIoco4EL1g4Tmk16EuJmKFCJRh_L3FQ/viewform?embedded=true"
                width="100%" height="1000" frameBorder="0" marginHeight="0"
                marginWidth="0" title="ショールームご来場ご予約フォーム">読み込んでいます…
            </iframe>
          </div>
        </div>
      </section>

      <section className="bread">
        <ul>
          <li>
            <Link to="/">
              <Image filename="ico/home.png" />
            </Link>
          </li>
          <li>お問い合わせ</li>
        </ul>
      </section>
    </Layout>
);

export default ContactPage;
